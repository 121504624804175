import React from 'react';
import './PrivacyPolicy.scss';
import img from './assets/img/privacy_header.png'
import Footer from './components/Footer';
import MobileFooter from './components/MobileFooter';

const privacyPolicy = ({width}) => {
	return (
<div>
			<section className="privacy-section">
					<div className="privacy-header">
						<div className="privacy-left">
							<h2 className='privacy-policy-title'>Privacy Policy</h2>
							<p className='privacy-policy-txt'>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our App (eFlow for Microsoft Teams, iOS, Android, and Moodle) and the choices you have associated with that data. We use your data to provide and improve the Service.</p>
						</div>
						<div className="privacy-left">
							<img src={img}></img>
						</div>
					</div>
					<div className="privacy-container">
						
						<h2 className='privacy-policy-title'>Scope of Application</h2>
						<p className='privacy-policy-txt'>This Privacy Policy applies to the use of the eFlow App, available on Microsoft Teams, Apple App Store (iOS), Google Play Store (Android), and the Moodle Plugin Directory. It governs how we collect, use, and protect personal information across all supported platforms.</p>

						<h2 className='privacy-policy-title'>Information Collection and Use</h2>
						<p className='privacy-policy-txt'>We collect several different types of information for various purposes to provide and improve our Service to you.</p>

						<h2 className='privacy-policy-title'>Types of Data Collected</h2>
						<h3 className='privacy-policy-sub-title'>Personal Data</h3>
						<p className='privacy-policy-txt'>While using our App, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>
						<ul className='privacy-policy-list'>
							<li>Email address</li>
							<li>First name and last name</li>
							<li>Phone number</li>
							<li>Gender</li>
							<li>Cookies and Usage data</li>
						</ul>

						<h3 className='privacy-policy-sub-title'>Usage Data</h3>
						<p className='privacy-policy-txt'>We may also collect information on how the App is accessed and used ("Usage Data"). This may include information such as device type, IP address, browser type, version, pages visited, time and date, time spent on pages, and other diagnostic data.</p>

						<h3 className='privacy-policy-sub-title'>Tracking and Cookies Data</h3>
						<p className='privacy-policy-txt'>We use cookies and similar tracking technologies to track the activity on our App and store certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.</p>

						<h3 className='privacy-policy-sub-title'>Use of Data</h3>
						<p className='privacy-policy-txt'>eFlow uses the collected data for various purposes:</p>
						<ul className='privacy-policy-list'>
							<li>To provide and maintain the App</li>
							<li>To notify you about changes to our App</li>
							<li>To allow you to participate in interactive features</li>
							<li>To provide customer support</li>
							<li>To gather analysis to improve our App</li>
							<li>To monitor usage</li>
							<li>To detect, prevent and resolve technical issues</li>
						</ul>

						<h3 className='privacy-policy-sub-title'>Transfer of Data</h3>
						<p className='privacy-policy-txt'>Your information may be transferred to — and maintained on — servers located outside your country. If you are outside Lebanon and provide information to us, it will be transferred and processed in Lebanon. We will ensure adequate safeguards are in place to protect your data.</p>

						<h2 className='privacy-policy-title'>Legal Requirements</h2>
						<p className='privacy-policy-txt'>eFlow may disclose your Personal Data if necessary to:</p>
						<ul className='privacy-policy-list'>
							<li>Comply with legal obligations</li>
							<li>Protect and defend our rights or property</li>
							<li>Investigate possible wrongdoing</li>
							<li>Protect user safety</li>
							<li>Protect against legal liability</li>
						</ul>

						<h3 className='privacy-policy-sub-title'>Security of Data</h3>
						<p className='privacy-policy-txt'>While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>

						<h3 className='privacy-policy-sub-title'>Service Providers</h3>
						<p className='privacy-policy-txt'>We may employ third-party companies and individuals to facilitate our App. These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>

						<h3 className='privacy-policy-sub-title'>Links To Other Sites</h3>
						<p className='privacy-policy-txt'>Our App may contain links to other sites. We advise you to review the Privacy Policy of every site you visit. We have no control over and assume no responsibility for third-party sites or services.</p>

						<h3 className='privacy-policy-sub-title'>Children’s Privacy</h3>
						<p className='privacy-policy-txt'>Our App does not address anyone under the age of 18. If we discover we have collected data from a child without verification of parental consent, we will delete it immediately.</p>

						<h3 className='privacy-policy-sub-title'>Changes to This Privacy Policy</h3>
						<p className='privacy-policy-txt'>We may update our Privacy Policy from time to time. You are advised to review this page periodically for changes. Changes are effective once posted on this page with the updated date.</p>

						<h3 className='privacy-policy-sub-title'>Your Rights and Choices</h3>
						<p className='privacy-policy-txt'>You can edit your account information or request deletion of your personal data by contacting us.</p>

						<h3 className='privacy-policy-sub-title'>Contact Us</h3>
						<p className='privacy-policy-txt'>If you have any questions about this Privacy Policy, please contact us at <span className='privacy-policy-email'>support@eflow.app</span></p>
					</div>
			</section>
			{width > 480 && <Footer />}
			{width < 480 && <MobileFooter />}
		</div>
	);
};

export default privacyPolicy;
